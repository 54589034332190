import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import NumberCounter from 'number-counter';
import { motion } from 'framer-motion';

const Hero = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const transition = { type: 'spring', duration: 3 };
  return (
    <div className='hero' id='home'>
      <div className='blur hero-blur'></div>
      <div className='left-h'>
        <Header />
        {/* The best ad */}
        <div className='the-best-ad'>
          <motion.div
            initial={{ left: mobile ? '165px' : '230px' }}
            whileInView={{ left: '8px' }}
            transition={{ ...transition, type: 'tween' }}
          ></motion.div>
          <span>The best Fitness Gym in The Town</span>
        </div>

        {/* Hero Heading */}
        <div className='hero-text'>
          <div>
            <span className='stroke-text'>Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/* Figures */}
        <div className='figures'>
          <div>
            <span>
              <NumberCounter end={140} start={100} delay='2000ms' preFix='+' />
            </span>
            <span>EXPERT COACHES</span>
          </div>
          <div>
            <span>
              {' '}
              <NumberCounter end={978} start={800} delay='2000ms' preFix='+' />
            </span>
            <span>MEMBERS JOINED</span>
          </div>
          <div>
            <span>
              <NumberCounter end={50} start={0} delay='2000ms' preFix='+' />
            </span>
            <span>FITNESS PROGRAMS</span>
          </div>
        </div>
        {/* Hero Buttons */}
        <div className='hero-buttons'>
          <div className='btn'>Get Started</div>
          <div className='btn'>Learn More</div>
        </div>
      </div>
      <div className='right-h'>
        <button className='btn'>Join Us</button>
        <motion.div
          initial={{ right: '-1rem' }}
          whileInView={{ right: '4rem' }}
          transition={transition}
          className='heart-rate'
        >
          <img src={Heart} alt='' />
          <span>Heart Rate</span>
          <span>114bpm</span>
        </motion.div>

        {/* hero images */}
        <img src={hero_image} alt='' className='hero_image' />
        <motion.img
          initial={{ right: '11rem' }}
          whileInView={{ right: '20rem' }}
          transition={transition}
          src={hero_image_back}
          alt=''
          className='hero_image_back'
        />
        {/* calories */}
        <motion.div
          className='calories'
          initial={{ right: '37rem' }}
          whileInView={{ right: '28rem' }}
          transition={transition}
        >
          <img src={Calories} alt='' />
          <div>
            <span>Calories Burned</span>
            <span>220 Kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
